import React, { useState, useRef } from 'react';

const Digitsinput = ({ length = 6 }) => {
    const [code, setCode] = useState(Array(length).fill(''));
    const inputRefs = useRef([]);

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Allow only digits
        if (/^\d*$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;

            setCode(newCode);

            // Move to the next input if the current input is filled
            if (value && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        // Move to the previous input on backspace
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <div className='digitsinput'>
            {code.map((digit, index) => (
                <input 
                    className={'digit ' + index.toString()}
                    key={index}
                    type="text"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    maxLength={1} // Limit input to 1 character
                />
            ))}
        </div>
    );
};

export default Digitsinput;
