import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import Cookies from 'universal-cookie';

import '../styles/Cpanel/Cpanel.css'
import '../styles/Cpanel/Cpanel_v2.css'
import logo from '../../files/logo.png'

import Dashboard from './components/Dashboard'
import Loading from '../Loading/Loading';

const { Config } = require('../config');


class Cpanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies : new Cookies() , verify: false};
    }

    forbidden() {
        this.state.cookies.remove("user-token");
        this.state.cookies.remove("username");
        this.state.cookies.remove("role");
        window.location.href = '/login';
    }
    roleCheck() {
        if(this.state.cookies.get('role') != "Coach" && this.state.cookies.get('role') != "coach" && this.state.cookies.get('role') != "Coache") {
            this.forbidden();
        }

        let role = "none";
        axios.get(
            Config.BASE_URL + "/user/getrole/",
            {
              headers: {
                'authorization': this.state.cookies.get("user-token"),
                "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
              }
            }
        ).then(res => {
            console.log(res);
            role = res.data.role;
            console.log(role);
            if(role != "Coach" && role != "coach" && role != "Coache") {
                this.forbidden();
            }
            else {
                this.setState({verify: true});
            }
        }).catch(error => {
            console.log(error);
            this.forbidden();
        });
    }
    
    render() {
        if(this.state.cookies.get('user-token') == null) {
            window.location.href = '/signup';
            return;
        }
        if(this.state.verify == false) {
            this.roleCheck();
            return (
                <div className = 'cpanel--body'>
                    <Loading />
                </div>
            );
        }
            
        return (
            <div className = 'cpanel--body'>
                <Dashboard value = 'mealmaker'/>
            </div>
        );
    }
}

export default Cpanel;