import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import ReactDOM from 'react-dom';

import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Cpanel from './pages/Cpanel/Cpanel';
import Tpanel from './pages/Tpanel/Tpanel';
import DietRegisterWithParams from './pages/DietRegister/WithParams';
import ForgotpasswordWithParams from './pages/Forgot/WithParams';
import Forgotpassword from './pages/Forgot/Forgotpassword';
import Cverification from './pages/Verification/Cverification';
import Tverification from './pages/Verification/Tverification';
import Csignup from './pages/Signup/Csignup';
import Tsignup from './pages/Signup/Tsignup';
import SignupEntry from './pages/Signup/SignupEntry';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path = "/" element = {<Home  />}/>
                <Route path = "/signupentry" element = {<SignupEntry />}/>
                <Route path = "/Csignup" element = {<Csignup />}/>
                <Route path = "/Tsignup" element = {<Tsignup />}/>
                <Route path = "/login" element = {<Login />}/>
                <Route path = "/Cverify" element = {<Cverification />}/>
                <Route path = "/Tverify" element = {<Tverification />}/>
                <Route path = "/Cpanel" element = {<Cpanel />}/>
                <Route path = "/Tpanel" element = {<Tpanel />}/>
                <Route path = "/forgot" element = {<Forgotpassword token={null}/>}/>
                <Route path = "/dietregister/:id" element = {<DietRegisterWithParams />}/>
                <Route path = "/forgot/:token" element = {<ForgotpasswordWithParams />}/>
                <Route path="*" element={<Home />} /> 
            </Routes>
        </BrowserRouter>
    );
}

export default App;