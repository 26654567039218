import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios';

import Cookies from 'universal-cookie';

import '../../../styles/Cpanel/Profile.css'
import '../../../styles/Cpanel/Profile_v2.css'
import Loading from '../../../Loading/Loading';
import { Config } from '../../../config';


class Activation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , sigRef: createRef() , catchedData: false , activation_code: ""};

        this.clearCanvas = this.clearCanvas.bind(this);
    }

    clearCanvas() {
        this.state.sigRef.current.clear();
    }

    getData() {
        axios.get(
            Config.BASE_URL + "/user/cprofile/",
            {
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            this.setState({catchedData: true , activation_code: res.data.activation_code});
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        if(this.state.catchedData == false) {
            setTimeout(() => {
                this.getData();
            }, "500");
            return (
                <div className='activation'>
                    <Loading />
                </div>
            );
        }
        else {

            return (
                <div className='activation'>
                    <span className='topic'>کد اختصاصی مربیگری شما در پلتفرم gymi:</span>
                    <div className='activation--box'>
                        <span className='activation--code'>{this.state.activation_code}</span>
                    </div>
                    <span className='share'>هنرجویان شما با این کد میتوانند در gymi ثبت نام کنند</span>
                    <div className='activation--sharing'>
                        <input className='phone' placeholder='تلفن همراه هنرجو'></input>
                        <button className='send'>ارسال کد مربیگری</button>
                    </div>
                </div>
            );
        }
    }
}

export default Activation