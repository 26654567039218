import { Config } from '../../../config';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
// cropImage.js
const sendimage = (image) => {
    // Step 1: Get the data URL from the canvas
    const dataURL = image;

    // Step 2: Convert the data URL to a Blob
    function dataURLtoBlob(dataURL) {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    const blob = dataURLtoBlob(dataURL);
    console.log("NOOOOOOOOOOOOOOOOOO")
    // Step 3: Create a FormData object and append the Blob
    const formData = new FormData();
    formData.append('image', blob, 'profile.jpg'); // You can specify a filename

    // Step 4: Send the FormData to the backend using Axios
    axios.post(Config.BASE_URL + "/user/uploadimage/", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'authorization': cookies.get("user-token"),
            "Access-Control-Allow-Origin": "*"
        }
    })
    .then(response => {
        console.log('Success:', response.data);
    })
    .catch(error => {
        console.error('Error:', error);
    });
}

const getCroppedImg = (imageSrc, pixelCrop) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.width = pixelCrop.width;
            canvas.height = pixelCrop.height;

            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                pixelCrop.width,
                pixelCrop.height
            );

            // Convert to circular image
            const circularCanvas = document.createElement('canvas');
            const circularCtx = circularCanvas.getContext('2d');
            circularCanvas.width = pixelCrop.width;
            circularCanvas.height = pixelCrop.height;

            circularCtx.beginPath();
            circularCtx.arc(
                circularCanvas.width / 2,
                circularCanvas.height / 2,
                circularCanvas.width / 2,
                0,
                Math.PI * 2,
                true
            );
            circularCtx.closePath();
            circularCtx.clip();

            circularCtx.drawImage(canvas, 0, 0);

            // Convert to base64
            sendimage(canvas.toDataURL('image/jpeg'));
            resolve(canvas.toDataURL('image/jpeg'));
        };

        image.onerror = (error) => {
            reject(error);
        };
    });
};

export default getCroppedImg;
