import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import logo from '../../files/logo2.png'

import Cookies from 'universal-cookie';

import '../styles/Verification.css';
import Loading from '../Loading/Loading';
import Digitsinput from './Digitsinput';

const { Config } = require('../config');

class Tverfication extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , changeNumberMode: false , loadingSuccessDialog: "" , loadingErrorDialog: "" , log: "" , smsSent: false , refreshDoc: false , lastTimer: null}
    } 

    verificationLoading() {
        document.getElementsByClassName("smsVerification")[0].style.display = 'none';
        document.getElementsByClassName("smsVerification")[1].style.display = 'flex';
        setTimeout(() => {
            document.getElementsByClassName("smsVerification")[1].style.opacity = "1";
        } , "100");
    }
    loadingSuccess(dialog) {
        let elem = document.getElementsByClassName("smsVerification")[1];
        this.setState({loadingSuccessDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
        } , "1000");
    }
    loadingError(dialog) {
        let elem = document.getElementsByClassName("smsVerification")[1];
        this.setState({loadingErrorDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--error")[0].style.display = 'flex';

        let is = this.state.smsSent;
        setTimeout(() => {
            this.loadingReset();
            if(is) this.setState({refreshDoc: !this.state.refreshDoc});
            else window.location.href = "/csignup";
        }, "1000");
    }
    loadingReset() {
        let elem = document.getElementsByClassName("smsVerification")[1];
        elem.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        elem.getElementsByClassName("loading--error")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'none';
    }

    // sendSMS() {
    //     axios.get(
    //         Config.BASE_URL + "/user/verify/",
    //         {
    //             headers: {
    //                 "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
    //             }
    //         }
    //     ).then(res => {
    //         console.log(res);
    //         if(res.data.status == "ok") {
    //             setTimeout(() => {
    //                 this.setState({smsSent: true , lastTimer: Date.now()});
    //             }, "1000");
    //             setTimeout(() => {
    //                 document.getElementsByClassName("resend")[0].style.opacity = "0.6";
    //                 setTimeout(() => {
    //                     document.getElementsByClassName("resend")[0].style.opacity = "1";
    //                 }, "30000");
    //             }, "2000");
    //             this.loadingSuccess("پیامک با موفقیت به شماره همراه شما ارسال شد");
    //         }
    //     }).catch(error => {
    //         console.log(error);
    //         this.loadingError("در ارسال پیامک خطایی پیش آمده دوباره تلاش کنید");
    //     });
    // }
    sendVerification() {
        let elem = document.getElementsByClassName("digit")
        let Code = elem[0].value + elem[1].value + elem[2].value + elem[3].value + elem[4].value + elem[5].value;
        this.verificationLoading();
        axios.post(
            Config.BASE_URL + "/user/verify/",
            {   
                phone_number: this.state.cookies.get("signup_phone"),
                code: Code
            },
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                }
            }
        ).then(res => {
            console.log(res);
            if(res.data.status == "ok") {
                this.loadingSuccess("تایید شد");
                setTimeout(() => {
                    this.signupUser();
                }, "1000")
            }
        }).catch(error => {
            console.log(error);
            this.loadingError("کد معتبر نمیباشد دوباره تلاش کنید");
        });
    }
    resend() {
        //30s
        if(Math.floor((Date.now() - this.state.lastTimer) / 1000) > 30) {
            this.setState({smsSent: false});
            document.getElementsByClassName("resend")[0].style.borderColor = "rgb(150, 150, 0)";
            setTimeout(() => {
                document.getElementsByClassName("resend")[0].style.borderColor = "white";
            }, '500');
        }
    }
    signupUser() {
        let Username = this.state.cookies.get("signup_username");
        let Password = this.state.cookies.get("signup_password");
        let Email = this.state.cookies.get("signup_email");
        let Phone = this.state.cookies.get("signup_phone");
        let Firstname = this.state.cookies.get("signup_firstname");
        let Lastname =this.state.cookies.get("signup_lastname");
        let Activation_code = this.state.cookies.get("signup_activation_code");
        
        Username = Username.toLowerCase();
        var pattern=/[\x00-\x7F]+/;
        var phonePattern = /^09\d{9}$/;
        if(Username.length < 5) {
            this.invalidRequest();
            return;
        }
        if(!pattern.test(Username)) {
            this.invalidRequest();
            return;
        }
        if(Password.length < 4) {
            this.invalidRequest();
            return;
        }
        if(!phonePattern.test(Phone)) {
            this.invalidRequest();
            return;
        }
        if(!pattern.test(Username) || (!pattern.test(Email) && Email.length > 0) || !pattern.test(Phone) || !pattern.test(Password)) {
            this.invalidRequest();
            return ;
        }

        axios.post(
            Config.BASE_URL + "/user/signup/",
            {   
                signuptype: 1,
                activation_code: Activation_code,
                username: Username,
                password: Password,
                email: Email,
                phone_number : Phone,
                first_name:Firstname,
                last_name:Lastname
            },
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                }
            }
        ).then(res => {
            console.log(res);
            if(res.data.status == "ok") {
                this.state.cookies.remove(this.state.cookies.getAll());
                this.state.cookies.set('user-token' , "Token " + res.data.token , {path: '/' , sameSite:'lax'});
                this.state.cookies.set('username' , Username, {path: '/' , sameSite:'lax'});
                this.state.cookies.set('role' , res.data.role, {path: '/' , sameSite:'lax'});
                this.loadingSuccess("حساب کاربری با موفقیت ساخته شد");
                setTimeout(() => {
                    window.location.href = "/";
                }, "1000");
            }
        }).catch(error => {
            console.log(error);
            this.invalidRequest();
            // if(error.response.data.error == "username already exists") {
            //     this.loadingError("نام کاربری تکراری می باشد");
            // }
            // else if(error.response.data.error == "phone already exists") {
            //     this.loadingError("شماره همراه تکراری می باشد");
            // }
            // else if(error.response.data.error == "phone not invited") {
            //     this.loadingError("شماره همراه شما به عنوان مربی تعریف نشده است");
            // }
            // else {
            //     this.loadingError("خطایی پیش آمده دوباره تلاش کنید!");
            // }
        })
    }

    invalidRequest() {
        let c = this.state.cookies.getAll();
        console.log(c)
        for(let i = 0; i < c.length; i = i + 1) {
            this.state.cookies.remove(c[i]);
        }
        window.location.href = "/signupentry";
    }

    // changeNumber() {
    //     let newPhone = document.getElementsByClassName("newPhone")[0].value;
    //     if(newPhone.length < 10) {
    //         this.setState({log: "شماره همراه معتبر نمیباشد"});
    //         return;
    //     }

    //     this.loading("justifyPhone");
    //     axios.post(
    //         Config.BASE_URL + "/user/phonevalidate/",
    //         {
    //             phone_number: newPhone
    //         },
    //         {
    //             headers: {
    //                 "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
    //             }
    //         }
    //     ).then(res => {
    //         console.log(res);
    //         if(res.data.status == "ok") {
    //             this.state.cookies.set("signup_phone" , newPhone);
    //             this.setState({smsSent: false , changeNumberMode: false , log: ""});
    //             this.loadingSuccess("justifyPhone" , "شماره همراه با موفقیت تغییر یافت")
    //         }
    //     }).catch(error => {
    //         if(error.response.data.error == "phone already exists") {
    //             this.loadingError("justifyPhone" , "شماره همراه تکراری می باشد");
    //         }
    //         else if(error.response.data.error == "phone not valid") {
    //             this.loadingError("justifyPhone" , "شماره همراه معتبر نمیباشد");
    //         }
    //         else {
    //             this.loadingError("justifyPhone" , "خطایی پیش آمده دوباره تلاش کنید!");
    //         }
    //     })
    // }
    

    render() {
        if(this.state.cookies.get("signup_phone") == undefined || this.state.cookies.get("signup_phone").length < 10) {
            this.invalidRequest();
            return ;
        }

        // if(this.state.smsSent == false) {
        //     return (
        //         <div className='verify body'>
        //             <div className='smsVerification' style={{display: 'none'}}></div>
        //             <div className='smsVerification loading' style={{display:'flex' , opacity: '1'}} onLoad={() => this.sendSMS()}>
        //                 <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
        //             </div>
        //         </div>
        //     );
        // }

        // if(this.state.changeNumberMode == true) {
        //     return (
        //         <div className='justifyPhone'>
        //             <input className='newPhone' placeholder='شماره همراه'></input>
        //             <button className='justifyPhone--button'>تایید</button>
        //             <button className='back' onClick={() => {setTimeout(() => {this.setState({changeNumberMode : false})} , "300")}}>برگشت</button>
        //             <span className='log--error'>
        //                 <ReactTyped 
        //                     strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
        //                     style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
        //                 />
        //             </span>
        //         </div>
        //     );
        // }

        return (
            <div className='verify body' key={this.state.refreshDoc ? 1 : 2}>
                <div className='smsVerification'>
                    <span className='topic'>کد تاییدیه به شماره همراه "{this.state.cookies.get("signup_phone")}" ارسال شده لطفا آن را وارد کنید</span>
                    <Digitsinput />
                    <button className='verify' onClick={() => this.sendVerification()}>تایید</button>
                    <div className='buttonseperator'>
                        <button className='resend' onClick={() => this.resend()}>ارسال مجدد</button>
                        <button className='justifyPhone' onClick={() => setTimeout(() => {window.location.href = "/tsignup"} , "300")}>تغییر شماره همراه</button>
                    </div>
                </div>
                <div className='smsVerification loading' style={{display:'none'}}>
                    <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                </div>
            </div>
        );
    }
}

export default Tverfication