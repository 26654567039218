import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { ReactTyped } from "react-typed";

import Loading from '../Loading/Loading';

import '../styles/Login/Login.css'
import '../styles/Login/Login_v2.css'
import logo from '../../files/banners/GYMi/LOGO/Picture3.png'
import cover from '../../files/homecover.jpg'
import Menubar from '../Home/Menubar';

const { Config } = require('../config');

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies() , log: ""};
        this.loginLoading = this.loginLoading.bind(this);
        this.loadingSuccess = this.loadingSuccess.bind(this);
        this.loadingError = this.loadingError.bind(this);
        this.loadingReset = this.loadingReset.bind(this);
        this.senddata = this.senddata.bind(this);
    }

    loginLoading() {
        document.getElementsByClassName("form")[0].style.display = 'none';
        document.getElementsByClassName("form")[1].style.display = 'flex';
        setTimeout(() => {
            document.getElementsByClassName("form")[1].style.opacity = "1";
        } , "100");
    }
    loadingSuccess() {
        document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        document.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            window.location.href = '/';
        } , "1000");
    }
    loadingError() {
        document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        document.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            window.location.href = '/login';
        }, "1000");
    }
    loadingReset() {
        document.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        document.getElementsByClassName("loading--error")[0].style.display = 'none';
        document.getElementsByClassName("loading--success")[0].style.display = 'none';
    }

    senddata() {
        let Username = document.getElementsByClassName("username")[0].value;
        let Password = document.getElementsByClassName("password")[0].value;
        // if(Username.length < 5) {
        //     this.setState({log: "نام کاربری نباید کمتر از ۵ حرف باشد"});
        //     return;
        // }
        // if(Password.length < 4) {
        //     this.setState({log: "رمز عبور نباید کمتر از ۴ حرف باشد"});
        //     return;
        // }
        this.loginLoading();
        axios.post(
            Config.BASE_URL + "/user/login/",
            {
              username: Username,
              password: Password
            },
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;",
              }
            }
        ).then(res => {
            console.log(res);
            this.state.cookies.set('user-token' , "Token " + res.data.token , {path: '/' , sameSite:'lax'});
            this.state.cookies.set('username' , Username, {path: '/' , sameSite:'lax'});
            this.state.cookies.set('role' , res.data.role, {path: '/' , sameSite:'lax'});
            console.log(this.state.cookies.get("role"));
            this.loadingSuccess();
        }).catch(error => {
            console.log(error);
            this.loadingError();
        });
    }   

    render() {
        console.log(this.state.cookies);
        if(this.state.cookies.get('user-token') != null) {
            window.location.href = '/';
            return;
        }
        return (
            <div className = "login">
                <Menubar />
                <div className='form'>
                    <div className='form--topic'>
                        <span className='login--topic'>ورود</span>
                    </div>
                    <input className='username' placeholder='نام کاربری' name='username' autoComplete='username' type='text'></input>
                    <input className='password' placeholder='رمز کاربری' name='password' autoComplete='current-password' type='password'></input>
                    <button className='login--button' onClick={() => this.senddata()}>تایید</button>
                    <a href="/signupentry" className='loginform--signup'>ثبت نام نکردید؟</a>
                    <a href="/forgot" className='loginform--forgot'>فراموش کردید؟</a>
                    <span className='log--error'>
                        <ReactTyped 
                            strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                            style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                        />
                    </span>
                </div>
                <div className='form loading' style={{display:'none'}}>
                    <Loading successDialog={"خوش آمدید"} errorDialog={"خطایی پیش آمده دوباره تلاش کنید!"}/>
                </div>
            </div>
        );
    }
}

export default Login;