import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { ReactTyped } from "react-typed";

import '../styles/Signup/Signup.css'
import '../styles/Signup/Signup_v2.css'
import logo from '../../files/banners/GYMi/LOGO/Picture3.png'
import cover from '../../files/homecover.jpg'
import Loading from '../Loading/Loading';
import Menubar from '../Home/Menubar';

const { Config } = require('../config');


class Csignup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies() , catchData: false , usernames: [] , userMode: null , log: "" , loadingSuccessDialog: "" , loadingErrorDialog: "" , verificationToken: "" , userPhone: ""};
        this.signupLoading = this.signupLoading.bind(this);
        this.loadingSuccess = this.loadingSuccess.bind(this);
        this.loadingError = this.loadingError.bind(this);
        this.loadingReset = this.loadingReset.bind(this);
        this.senddata = this.senddata.bind(this);
        this.getUsernamesData = this.getUsernamesData.bind(this);
        this.checkUsername = this.checkUsername.bind(this);
    }

    signupLoading() {
        document.getElementsByClassName("form")[0].style.display = 'none';
        document.getElementsByClassName("form")[1].style.display = 'flex';
        setTimeout(() => {
            document.getElementsByClassName("form")[1].style.opacity = "1";
        } , "100");
    }
    loadingSuccess(dialog) {
        let elem = document.getElementsByClassName("form")[1];
        this.setState({loadingSuccessDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            this.verificationLoad();
        } , "1000");
    }
    loadingError(dialog) {
        let elem = document.getElementsByClassName("form")[1];
        this.setState({loadingErrorDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            window.location.href = '/signup';
        }, "1000");
    }
    loadingReset() {
        let elem = document.getElementsByClassName("form")[1];
        elem.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        elem.getElementsByClassName("loading--error")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'none';
    }

    verificationLoad() {
        document.getElementsByClassName("form")[1].style.opacity = '0';
        setTimeout(() => {
            document.getElementsByClassName("form")[1].style.display = 'none';
            document.getElementsByClassName("form")[2].style.display = 'flex';
            setTimeout(() => {
                document.getElementsByClassName("form")[2].style.opacity = "1";
            } , "100");
        }, "100");
    }

    senddata() {
        let Username = document.getElementsByClassName("username")[0].value;
        let Password = document.getElementsByClassName("password")[0].value;
        let Email = document.getElementsByClassName("email")[0].value;
        let Phone = document.getElementsByClassName("phone")[0].value;
        let Firstname = document.getElementsByClassName("firstname")[0].value;
        let Lastname = document.getElementsByClassName("lastname")[0].value;
        Username = Username.toLowerCase();
        var pattern=/[\x00-\x7F]+/;
        var phonePattern = /^09\d{9}$/;
        if(Username.length < 5) {
            this.setState({log: "نام کاربری نباید کمتر از ۵ حرف باشد"});
            return;
        }
        if(!pattern.test(Username)) {
            this.setState({log: "نام کاربری باید از حروف لاتین تشکیل شود"});
            return;
        }
        if(Password.length < 4) {
            this.setState({log: "رمز عبور نباید کمتر از ۴ حرف باشد"});
            return;
        }
        if(Password != document.getElementsByClassName("password--verify")[0].value) {
            this.setState({log: "رمز با تاییدیه آن برابر نمیباشد"});
            return;
        }
        if(!phonePattern.test(Phone)) {
            this.setState({log: "شماره همراه معتبر باید از ارقام لاتین و ۱۱ حرف باشد"});
            return;
        }
        if(!pattern.test(Username) || (!pattern.test(Email) && Email.length > 0) || !pattern.test(Phone) || !pattern.test(Password)) {
            this.setState({log: "جز در نام و نام خانوادگی باید از حروف و ارقام لاتین استفاده شود"});
            return ;
        }

        this.state.cookies.set('signup_username' , Username , {path: '/' , sameSite:'lax'});
        this.state.cookies.set('signup_password' , Password , {path: '/' , sameSite:'lax'});
        this.state.cookies.set('signup_email' , Email , {path: '/' , sameSite:'lax'});
        this.state.cookies.set('signup_phone' , Phone , {path: '/' , sameSite:'lax'});
        this.state.cookies.set('signup_firstname' , Firstname , {path: '/' , sameSite:'lax'});
        this.state.cookies.set('signup_lastname' , Lastname , {path: '/' , sameSite:'lax'});

        this.signupLoading();

        axios.post(
            Config.BASE_URL + "/user/signupvalidate/",
            {
                signuptype: 0,
                username: Username,
                password: Password,
                email: Email,
                phone_number : Phone,
                first_name:Firstname,
                last_name:Lastname
            },
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                }
            }
        ).then(res => {
            console.log(res);
            if(res.data.status == "ok") {
                window.location.href = "/cverify";
            }
        }).catch(error => {
            if(error.response.data.error == "username already exists") {
                this.loadingError("نام کاربری تکراری می باشد");
            }
            else if(error.response.data.error == "phone already exists") {
                this.loadingError("شماره همراه تکراری می باشد");
            }
            else if(error.response.data.error == "phone not invited") {
                this.loadingError("شماره همراه شما به عنوان مربی تعریف نشده است");
            }
            else {
                this.loadingError("خطایی پیش آمده دوباره تلاش کنید!");
            }
        })
    }   

    getUsernamesData() {
        let allusernames = [];
        axios.get(
            Config.BASE_URL + "/user/allusers/",
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                }
            }
        ).then(res => {
            console.log(res);
            allusernames = res.data;
            for (let i = 0; i < allusernames.length; i = i + 1) {
                allusernames[i] = allusernames[i].toLowerCase();
            }
            this.setState({catchData: true , usernames: allusernames});
        }).catch(error => {
            console.log(error);
        });
    }
    checkUsername() {
        let u = document.getElementsByClassName("username")[0].value;
        u = u.toLowerCase();
        document.getElementsByClassName("username")[0].value = u;
        var pattern=/[\x00-\x7F]+/;
        if(u.length < 5 || this.state.usernames.includes(u) || !pattern.test(u)) {
            document.getElementsByClassName("username")[0].style.borderColor = "rgb(100,0,0)";
        }
        else {
            document.getElementsByClassName("username")[0].style.borderColor = "rgb(0,100,0)";
        }
    }
    checkPassword() {
        let p1 = document.getElementsByClassName("password")[0].value;
        let p2 = document.getElementsByClassName("password--verify")[0].value;
        var pattern=/[\x00-\x7F]+/;
        if(p1.length < 4 || !pattern.test(p1)) {
            document.getElementsByClassName("password")[0].style.borderColor = "rgb(100,0,0)";
            document.getElementsByClassName("password--verify")[0].style.borderColor = "rgb(100,0,0)";
        }
        else if(p1 != p2) {
            document.getElementsByClassName("password")[0].style.borderColor = "rgb(0,100,0)";
            document.getElementsByClassName("password--verify")[0].style.borderColor = "rgb(100,0,0)";
        }
        else {
            document.getElementsByClassName("password")[0].style.borderColor = "rgb(0,100,0)";
            document.getElementsByClassName("password--verify")[0].style.borderColor = "rgb(0,100,0)";
        }
    }

    render() {
        if(this.state.cookies.get('user-token') != null) {
            window.location.href = '/';
            return;
        }

        if(this.state.catchData == false) {
            this.getUsernamesData();
        }

        return (
            <div className = "signup">
                <Menubar />
                <div className='form coach'>
                    <div className='form--topic'>
                        <span className='signup--topic'>مربی</span>
                    </div>
                    <input className='username' placeholder='نام کاربری' value={this.state.cookies.get("signup_username")} onChange={() => this.checkUsername()}></input>
                    <input className='firstname' placeholder='نام' value={this.state.cookies.get("signup_firstname")} ></input>
                    <input className='lastname' placeholder='نام خانوادگی' value={this.state.cookies.get("signup_lastname")} ></input>
                    <input className='phone' placeholder='شماره همراه' value={this.state.cookies.get("signup_phone")} ></input>
                    <input className='email' placeholder='(اختیاری)ایمیل' value={this.state.cookies.get("signup_email")} ></input>
                    <input className='password' placeholder='رمز کاربری' value={this.state.cookies.get("signup_password")}  onChange={() => this.checkPassword()}></input>
                    <input className='password--verify' placeholder='تایید رمز کاربری' onChange={() => this.checkPassword()}></input>
                    <button className='signup--button' onClick={() => this.senddata()}>تایید</button>
                    <a href="/login" className='login'>ثبت نام کردید؟ وارد شوید</a>
                    <span className='log--error'>
                        <ReactTyped 
                            strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                            style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                        />
                    </span>
                </div>
                <div className='form coach loading' style={{display:'none'}}>
                    <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                </div>
            </div>
        );
    }
}

export default Csignup;