import React, { useState, useCallback, useRef} from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImg'; // Function to get cropped image
import axios from 'axios';
import Cookies from 'universal-cookie';

const { Config } = require('../../../config');

const ImageUploader = () => {
    const cookies = new Cookies();
    const [dataFetch , setDataFetch] = useState(false);
    const [hasImage , setHasImage] = useState(false);
    const [hasImageURL , setHasImageURL] = useState(null);
    const fileInputRef = useRef(null);
    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [isCropping, setIsCropping] = useState(false);

    const getProfileImage = () => {
        axios.get(
            Config.BASE_URL + "/user/imageurl",
            {
                headers: {
                    'authorization': cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
            }
        ).then(res => {
            console.log(res);
            if(res.data.status == "ok") {
                setHasImage(true);
                setHasImageURL(res.data.url);
            }
            setDataFetch(true);
        }).catch(error => {
            console.log(error);
            setDataFetch(true);
        });
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleUploadButton = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
                setIsCropping(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCrop = async () => {
        try {
            const croppedImageUrl = await getCroppedImg(image, croppedAreaPixels);
            setCroppedImage(croppedImageUrl);
            setIsCropping(false);
        } catch (error) {
            console.error(error);
        }
    };

    if(dataFetch == false) {
        getProfileImage();
    }
    else {
        if(hasImage == true) {
            return(
                <div className='profileimage'>
                    <div className='imageframe'>
                        <img className='image' src={hasImageURL}></img>
                    </div>
                    <button className='upload' onClick={handleUploadButton}>آپلود</button>
                    <input className='inputfile'
                        type="file"
                        ref={fileInputRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        style={{ display: 'none' }}
                    />
                </div>
            );
        }
        if(isCropping == true) {
            return(
                <div className='profileimage'>
                    <div className='imageframe'>
                        <div className='crop-container'>
                            <Cropper
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={1} // 1:1 aspect ratio for circular crop
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </div>
                    </div>
                    <button className='crop' onClick={handleCrop}>
                        تنظیم
                    </button>
                </div>
            );
        }
        if(croppedImage) {
            return(
                <div className='profileimage'>
                    <div className='imageframe'>
                        <img
                            src={croppedImage}
                            alt="Cropped"
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                left: '0%',
                                top: '0%',
                                borderRadius: '1rem',
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                    <button className='upload' onClick={handleUploadButton}>آپلود</button>
                    <input className='inputfile'
                        type="file"
                        ref={fileInputRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        style={{ display: 'none' }}
                    />
                </div>
            );
        }
        else {
            return(
                <div className='profileimage'>
                    <div className='imageframe'></div>
                    <button className='upload' onClick={handleUploadButton}>آپلود</button>
                    <input className='inputfile'
                        type="file"
                        ref={fileInputRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        style={{ display: 'none' }}
                    />
                </div>
            );
        }
    }
};

export default ImageUploader;
